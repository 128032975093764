import React, { Component } from 'react'
import "./Career.css"

class Career extends Component {
  render() {
    return (
      <div className='ContentFrame'>
        <h2>Career</h2>
        <div className='TextFrame'>
        <p>
        Hello. I’m Ruize (Ethan) Sheng.
        </p>
        <p>
        I’m a Master's student in Electrical & Computer Engineering at Carleton University. I hold a Bachelor's degree at Jilin University in Software Engineering. I mainly focus on web development and machine learning. My long-term goal is to combine AI/ML into web app to contribute to our society. So I’m looking for an internship in these areas to kick off my career in the industry.
        </p>
        <p>
        Technical skills:
        </p>
        <ul>
          <li>Languages: Java, Python, C++, JavaScript </li>
          <li>Technologies/Frameworks: HTML5, CSS, Node.js, React.js, REST API, Oracle, MySQL, MongoDB，Java EE </li>
          <li>Developer Environments/Tools: Windows, Linux, AWS EC2, Git, Visual Studio, Eclipse, VS Code, IntelliJ, PyCharm, Nginx</li>
        </ul>
        
        <p>
        I’m continuing to learn new technologies, currently, I’m learning Spring framework. I’m also open to other web technologies. For example, I had experience with Unity and C# scripts in Unity. So I can also transfer to Microsoft C# and .NET framework.
        </p>
        <p>
        I also took courses about Machine Learning and know the fundamentals and methodology, including Data preprocessing, Performance metrics, Training models, and validation methods (such as cross-validation, Leave-One-Out).
        </p>
        <p>
          Links to my projects (GitHub links):
        </p>
        <dl>
          <li>
            Personal Website (JavaScript, Node.js, React.js, REST API, MongoDB, Nginx)
          </li>
          <dd>
            <a href='https://github.com/AP1ruize/personal-website-frontend-reactjs'>
            frontend: React.js Project
            </a> 
          </dd>
          <dd>
            <a href='https://github.com/AP1ruize/personal-website-nodejs-api'>
            REST APIs: Express.js app
            </a>
          </dd>
          <li>
          <a href='https://github.com/AP1ruize/oracle_assig'>Library Management System (Java, HTML, CSS, JS, Oracle, IntelliJ)</a>
          </li>
          <dd>
            <a>A preview of the web homepage of this project is shown below, to demonstrate the web design.</a>
          </dd>
          <li>
          <a href='https://github.com/AP1ruize/online_exam_system_j2ee'>Online Exam System (Java EE, MySQL, Eclipse)</a>
          </li>
          <li>
          <a href='https://github.com/AP1ruize/sentiment_classfication_of_movie_reviews_3rd_yrs_proj'>Sentiment Classification of Movie Reviews (Python, PyCharm)</a>
          </li>
        </dl>
        
        </div>
        
        <div className='TextFrame'>
          <p>The frame below is the preview of the web design of the Library Management System project</p>
          <p>Notice: This frame ONLY shows the dynamic responsive effort of the web page design. Because the preview isn't connected to the backend, functions of the system don't work in the preview. </p>
          <a href='/lms.html'>click here to show the preview in full screen mode</a>
          <div className='Content'>
          <p>
            <iframe align="center" width="90%" height="600" src="./lms.html"  frameborder="yes" border="2px" marginwidth="2px" marginheight="2px" scrolling="yes"></iframe>
          </p>
          </div>
          
        </div>
        
        
      </div>
      
    )
  }
}

export default  Career;